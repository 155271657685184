import React, { Component } from 'react'
import * as d3 from 'd3'

const chart = (rootEl) => {
  var width = 960,
    height = 1500,
    cellSize = 25 // cell size

  const no_months_in_a_row = 3 //Math.floor(width / (cellSize * 7 + 50));
  const shift_up = cellSize * 3

  // TODO: use d3-time intervals instead of formattings to determine what week
  // a day is in and what day of the week it is (so you can change what day
  // is the start of the visual.)
  // Update: for now this works, by uing %W which gives the monday based
  // week of the year
  // const mondays = d3.timeMonday()
  var day = d3.timeFormat("%w"), // day of the week
      // day_of_month = d3.timeFormat("%e"), // day of the month
      // day_of_year = d3.timeFormat("%j"),
      week = d3.timeFormat("%W"), // week number of the year
      month = d3.timeFormat("%m"), // month number
      year = d3.timeFormat("%Y")
      // percent = d3.format(".1%"),
      // format = d3.timeFormat("%Y-%m-%d")

  const years = [new Date().getFullYear(), new Date().getFullYear() + 1]

  // console.log('no-months in row: ', no_months_in_a_row)

      /*
  var color = d3.scaleQuantize()
      .domain([-.05, .05])
      .range(d3.range(11).map(function(d) { return "q" + d + "-11"; }))
      */

  var svg = d3.select(rootEl).selectAll('svg')
      .data(d3.range(years[0], years[1]))
    .enter().append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('class', 'RdYlGn')
      .style('border', '1px solid')
      .style('background', 'white')
    .append('g')

  const today = new Date()

  /* Make weeks start on a Monday */
  const dayOfWeek = date => {
    const d = Number(day(date))
    if (d === 0) return 6
    return d - 1
  }

  /*
  TODO: we probably need a group for the rect + text if you want to put day
  of month numbers in the cells.
  */
  const cellGroup = svg.selectAll(".day")
      // .data((d) => d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
      .data((d) => d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
    .enter()
      .append('g')

    cellGroup
      .append('rect')
      .attr('stroke', '#454545')
      .attr('fill', d => {
        if (d3.timeDay(today) < d3.timeDay(d)) {
          return 'white'
        }
        if (d3.timeDay(today).getTime() === d3.timeDay(d).getTime()) {
          return 'turquoise'
        }
        return '#6f6fa7'
      })
      .attr("class", d => {
      })
      .attr("width", cellSize)
      .attr("height", cellSize)
      .attr('x', function(d) {
        const monthPadding = 1.2 * cellSize * 7 * ((month(d)-1) % (no_months_in_a_row));
        return dayOfWeek(d) * cellSize + monthPadding + 50;
      })
      .attr('y', function(d) {
        var weekIndex = week(d) - week(new Date(year(d), month(d)-1, 1) );
        var row_level = Math.ceil(month(d) / (no_months_in_a_row));
        return (weekIndex * cellSize) + row_level*cellSize*8 - cellSize/2 - shift_up;
      })

      cellGroup
      .append('text')
      .text(d => d.getDate())
      .attr('x', function(d) {
        const monthPadding = 1.2 * cellSize * 7 * ((month(d)-1) % no_months_in_a_row);
        return dayOfWeek(d) * cellSize + monthPadding + 53;
      })
      .attr('y', function(d) {
        var weekIndex = week(d) - week(new Date(year(d), month(d)-1, 1) );
        var row_level = Math.ceil(month(d) / no_months_in_a_row);
        return (weekIndex * cellSize) + row_level*cellSize*8 - cellSize/2 - shift_up + 20;
      });

  svg.selectAll('.month-title')
        .data(d => d3.timeMonths(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
      .enter()
        .append("text")
        .text(monthTitle)
        .attr('x', (d, i) =>
          (1.2 * cellSize * 7 * ((month(d)-1) % (no_months_in_a_row))) + 80
        )
        .attr('y', (d, i) => {
          const weekDiff = week(d) - week(new Date(year(d), month(d)-1, 1) );
          const rowLevel = Math.ceil(month(d) / (no_months_in_a_row));
          return (weekDiff * cellSize) + (rowLevel * cellSize * 8) - cellSize - shift_up
        })
        .attr('class', 'month-title')
        .attr('d', monthTitle)

  svg.selectAll(".year-title")  // Jan, Feb, Mar and the whatnot
        .data(d => d3.timeYears(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
      .enter().append("text")
        .text(yearTitle)
        .attr("x", function(d, i) { return width/2 - 100; })
        .attr("y", function(d, i) { return cellSize*5.5 - shift_up; })
        .attr("class", "year-title")
        .attr("d", yearTitle)


  //  Tooltip Object
  /*
  var tooltip = d3.select("body")
    .append("div").attr("id", "tooltip")
    .style("position", "absolute")
    .style("z-index", "10")
    .style("visibility", "hidden")
    .text("a simple tooltip");
    */

  /*
  d3.csv("dji.csv", function(error, csv) {
    var data = d3.nest()
      .key(function(d) { return d.Date; })
      .rollup(function(d) { return (d[0].Close - d[0].Open) / d[0].Open; })
      .map(csv);

    rect.filter(function(d) { return d in data; })
        .attr("class", function(d) { return "day " + color(data[d]); })
      .select("title")
        .text(function(d) { return d + ": " + percent(data[d]); });

    //  Tooltip
    rect.on("mouseover", mouseover);
    rect.on("mouseout", mouseout);
    function mouseover(d) {
      tooltip.style("visibility", "visible");
      var percent_data = (data[d] !== undefined) ? percent(data[d]) : percent(0);
      var purchase_text = d + ": " + percent_data;

      tooltip.transition()
                  .duration(200)
                  .style("opacity", .9);
      tooltip.html(purchase_text)
                  .style("left", (d3.event.pageX)+30 + "px")
                  .style("top", (d3.event.pageY) + "px");
    }
    function mouseout (d) {
      // tooltip.transition()
      //         .duration(500)
      //         .style("opacity", 0);
      // var $tooltip = $("#tooltip");
      // $tooltip.empty();
    }
  });
    */

  // function dayTitle (t0) {
  //   return t0.toString().split(" ")[2];
  // }

  function monthTitle (t0) {
    return t0.toLocaleString('en-us', { month: 'long' })
  }

  function yearTitle (t0) {
    return t0.toString().split(' ')[3]
  }

}

class Calendar extends Component {
  componentDidMount() {
    chart(this.containerEl)
  }

  render() {
    return (
      <div style={{maxWidth: '800px', margin: '0 auto'}}>
        <div ref={el => this.containerEl = el} />
      </div>
    )
  }
}

export default Calendar
